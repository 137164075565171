import React from "react";
import { SEO, PageLayout } from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class HiringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"hiring-page"} hero={null}>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"center"}>
              <Header as={"h1"} textAlign={"center"}>
                Hiring
              </Header>
              <Button
                as={"a"}
                target={"_blank"}
                download={true}
                href={
                  "https://fisherman.gumlet.io/public/brightstarrestaurant/Bright_Star_Employment_Application_1.pdf"
                }
              >
                Download Job Application
              </Button>
              <p>
                <span>
                  Want to join our team? Please complete the job application
                  linked above and email it to&nbsp;
                </span>
                <a
                  href={"mailto:stwittybstar@gmail.com"}
                  target={"__blank"}
                  className={"email-job-application"}
                >
                  stwittybstar@gmail.com
                </a>
              </p>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Hiring" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
